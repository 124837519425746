@font-face {
  font-family: Poppins;
  src: url('../fonts/poppins.ttf') format('truetype');
  font-weight: 400;
}
.cosmic-nft-marketplace {
  margin-top: 68px;
  padding: 8px;
  margin-bottom: 76px;
  a {
    text-decoration: none;
    //color: inherit;
  }
  @media (min-width: 600px) {
    padding: 16px;
    margin-left: 84px;
    margin-bottom: 0;
  }
}
//
//.input-light {
//  input:-webkit-autofill,
//  input:-webkit-autofill:hover,
//  input:-webkit-autofill:focus,
//  input:-webkit-autofill:active{
//    -webkit-box-shadow: 0 0 0 30px #e0e0e0 inset !important;
//  }
//}
//
//.input-dark {
//  input:-webkit-autofill,
//  input:-webkit-autofill:hover,
//  input:-webkit-autofill:focus,
//  input:-webkit-autofill:active{
//    -webkit-box-shadow: 0 0 0 30px #6d6d6d inset !important;
//  }
//}

body {
  //-webkit-user-select: none; /* Safari */
  //-moz-user-select: none; /* Firefox */
  //-ms-user-select: none; /* IE10+/Edge */
  //user-select: none; /* Standard */
  overflow-y: overlay;
  scrollbar-width: thin;
  // overscroll-behavior-y: contain;
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    position: absolute;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 4px;
    opacity: 0.2;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #3b4046;
    border-radius: 4px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  &::-webkit-scrollbar-corner {
    width: 0;
  }
}

*::-webkit-scrollbar {
  width: 8px;
  height: 4px;
  border-radius: 4px;
}

*::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 4px;
  opacity: 0.2;
}

*::-webkit-scrollbar-thumb {
  background: #707070;
  border-radius: 4px;
}

*::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.small-scrollbar {
  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    border-radius: 4px;
  }
}

body {
  -webkit-tap-highlight-color: transparent;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

//&::-webkit-scrollbar {
//  width: 8px;
//  height: 8px;
//  border-radius: 0px;
//  position: absolute;
//}
//
///* Track */
//&::-webkit-scrollbar-track {
//  background: rgb(112, 128, 144);
//  border-radius: 0;
//  opacity: 1;
//}
//
///* Handle */
//&::-webkit-scrollbar-thumb {
//  background: #3b4046;
//  border-radius: 0px;
//}
//
///* Handle on hover */
//&::-webkit-scrollbar-thumb:hover {
//  background: #3b4046;
//}